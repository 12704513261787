// Login scripts
var APPLICATION_URL =
  "https://" + window.location.host + "/wp-admin/admin-ajax.php";

jQuery(document).ready(function ($) {
  // set placeholder title on the input and hide the label on the login form
  $(
    '.credentials_content form input:not([type="submit"] [type="select"])'
  ).each(function () {
    var placeholder_title = $(this).prev("label").html();
    $(this).prev("label").hide();
    $(this).attr("placeholder", placeholder_title);
  });
});

function get_param(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results == null) return "";
  else return results[1];
}

function resendVerificationEmail(e, email) {
  const $ = jQuery;
  e.preventDefault();
  $(e.target).attr("disabled", "disabled");
  $(e.target).after('<p class="pf_loading">&nbsp;</p>');

  $.get(
    "/wp-admin/admin-ajax.php?action=ab_resend_verification&user_email=" +
      email,
    function (r) {
      if (r.status == true) {
        $(".pf_error").remove();
        $(".verify-email-button").remove();
        $(".pf_loading").remove();
        $('[data-js="verify_status"]')
          .removeClass("hidden")
          .html("Success! A new verification email has been sent.");
      } else {
        $(e.target).removeAttr("disabled");
      }
    }
  );
}

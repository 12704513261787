jQuery(document).ready(function ($) {
  // global
  window.ab_user_id = $("meta[name=data-user-id]").attr("content");
  window.ab_page_resource_id = $("meta[name=data-resource-id]").attr("content");
  window.ab_page_action = $("meta[name=data-action]").attr("content");
  window.ab_post_type = $("meta[name=data-post-type]").attr("content");

  if (typeof disable_other_statuses != "undefined" && disable_other_statuses) {
    $("select[name=input_" + disable_other_statuses_input + "]").val(
      disable_other_statuses
    );
    $(
      "select[name=input_" +
        disable_other_statuses_input +
        '] option[value!="' +
        disable_other_statuses +
        '"]'
    ).remove();
  }
  // Pass the purpose of the form in with the form. Value gets set via functions_form.php->ab_generate_form()
  $(".gform_wrapper form").each(function () {
    $("<input>")
      .attr({
        type: "hidden",
        name: "ab_form_type",
        value: $(this).closest(".data_holder").attr("data-form-type"),
      })
      .appendTo($(this));
    $("<input>")
      .attr({
        type: "hidden",
        name: "ab_resource_id",
        value: $(this).closest(".data_holder").attr("data-resource-id"),
      })
      .appendTo($(this));
  });

  // hide disabled forms
  $(".gform_wrapper form.disabled").each(function () {
    $("<input>")
      .attr({
        type: "hidden",
        name: "ab_disabled",
        value: $(this).closest(".data_holder").attr("data-resource-id"),
      })
      .appendTo($(this));
    $(this).find("input").prop("disabled", true);
    $(this).find("select").prop("disabled", true);
    $(this).find('[type="submit"]').hide();
  });

  $(".confirm_delete").click(function () {
    if (confirm("Are you sure you want to delete this?")) {
      window.location.href = this.href;
    } else {
      return false;
    }
  });

  // use anchor tag to submit form instead of submit button, check to see if there is a confirm_delete class and then make sure it has been confirmed
  $(".submit_form").click(function () {
    if ($(this).hasClass("confirm")) {
      if (
        confirm("Are you sure you want to delete this?\nThis can not be undone")
      ) {
        $(this).closest("form").submit();
      } else {
        return false;
      }
    } else {
      $(this).closest("form").submit();
    }
  });

  $(".copy_paste").click(function () {
    $link = this.href;
    alert(
      "Copy the link below and paste it into your email client.\nPress CMD + C on Mac or CTRL + C on PC.\n\n" +
        this.href +
        "\n\n"
    );
    return false;
  });

  $(".form_number, .form_number input").on(
    "propertychange change input",
    function (e) {
      $(this).val(function (index, value) {
        return value.replace(/\D/g, "");
      });
    }
  );

  // Form Validation http://jqueryvalidation.org/
  // Add a class of validate to enable validation for any form. Give input a class of required to activate for that field.
  $("form.validate").each(function () {
    addValidation(this);
  });

  // select all
  $(".select_all").click(function (event) {
    //on click
    var check = this.checked;
    $(":checkbox").each(function () {
      this.checked = check;
    });
  });

  //Singleton for available deal field values
  var deal_field_values = (function () {
    var fieldValues;

    function setFieldValues() {
      jQuery.ajax({
        url: ajaxurl,
        data: {
          action: "ab_get_deal_field_values",
        },
        dataType: "json",
        async: false,
        cache: false,
        success: function (data) {
          fieldValues = data;
        },
      });
      return fieldValues;
    }

    return {
      getFieldValues: function () {
        return fieldValues || setFieldValues();
      },
    };
  })();

  /*
   * this method targets deal_playbook_field dropdowns in System->Pipeline
   */
  $(document).on("change", ".deal_playbook_field", function (e) {
    e.preventDefault();
    var dealFielValues = deal_field_values.getFieldValues();
    var fieldSelected = $(this).val();
    var select_value = $(this).parent().children(".deal_playbook_value");
    var options_value = "<option value=''>-- Select --</option>";

    if (dealFielValues[fieldSelected]) {
      if (fieldSelected === "owner_id") {
        options_value = "";
      }

      for (var i = 0; i < dealFielValues[fieldSelected].length; i++) {
        options_value +=
          "<option value='" +
          dealFielValues[fieldSelected][i]["value"] +
          "'>" +
          dealFielValues[fieldSelected][i]["label"] +
          "</option>";
      }
    }
    select_value.html(options_value);
  });

  // Pendo tracking for second partner dropdown
  $(".ab_sp_dropdown select").attr("data-tracking-id", "mtd_ab_second_partner");

  var original_owner_dropdown_content = $(
    ".owner_id_dropdown div select"
  ).html();
  var owner_dropdown = $(".owner_id_dropdown div select");
  var ab_partner_contact_dropdown = $(
    ".ab_second_partner_user_dropdown div select"
  );
  var company_dropdown = $(".ab_partner_company_dropdown div select");
  var company_label = $(".ab_partner_company_dropdown label");
  var overview_container = $(".pipeline_overview");

  var selected_value = $(".ab_partner_company_dropdown").find("select").val();

  function populate_owner_dropdown(user_data) {
    var parsed_user_data = JSON.parse(user_data);
    owner_dropdown.empty();

    const selectedOwnerId = sessionStorage.getItem("owner_mtd");

    parsed_user_data.forEach((user) => {
      var option = $("<option>", {
        value: user.legacy_id,
        text: user.first_name + " " + user.last_name,
      });
      owner_dropdown.append(option);
    });

    if (selectedOwnerId) {
      owner_dropdown
        .find(`option[value='${selectedOwnerId}']`)
        .prop("selected", true);
    }

    // remove loading overlay
    overview_container.removeClass("is_loading");
  }

  function populate_partner_contact_dropdown(user_data) {
    var parsed_user_data = JSON.parse(user_data);
    ab_partner_contact_dropdown.empty();

    ab_partner_contact_dropdown.append(
      $("<option>", {
        value: " ",
        text: "-- Select --",
      })
    );

    parsed_user_data.forEach((user) => {
      var option = $("<option>", {
        value: user.legacy_id,
        text: user.first_name + " " + user.last_name,
      });
      ab_partner_contact_dropdown.append(option);
    });

    // remove loading overlay
    overview_container.removeClass("is_loading");
  }

  function get_and_populate_user_data(selected_value) {
    // Use selected_value as mongo_company_id
    const mongo_company_id = selected_value;

    jQuery.ajax({
      url: APPLICATION_URL,
      async: true,
      data: {
        action: "ab_get_company_users",
        mongo_company_id: mongo_company_id,
      },
      type: "GET",
      success: function (user_data) {
        populate_owner_dropdown(user_data);
      },
      error: function (request, status, error) {
        console.log("Error: " + error);
        overview_container.removeClass("is_loading");
      },
    });
  }

  $(".ab_partner_company_dropdown").on("change", function () {
    var selected_value = $(this).find("select").val();
    if (selected_value == " ") {
      owner_dropdown.empty();
      return;
    }

    // add loading overlay
    overview_container.addClass("is_loading");

    get_and_populate_user_data(selected_value);
  });

  function revert_owner_dropdown() {
    owner_dropdown.html(original_owner_dropdown_content);
  }

  $(".behalf_of_partner_dropdown").on("change", function (event) {
    var selected_value = $(this).find("input:checked").val();
    if (selected_value == "No") {
      revert_owner_dropdown();
      company_dropdown.hide();
      company_label.hide();
      sessionStorage.removeItem("owner_mtd");
      event.stopPropagation(); // Prevent further propagation
    } else {
      company_dropdown.show();
      company_label.show();
      owner_dropdown.empty();
      company_dropdown.find("option[value=' ']").prop("selected", true);
      owner_dropdown.on("change", storeOwnerDropdownValue);
    }
  });

  var initial_behalf_of_partner_value = $(
    ".behalf_of_partner_dropdown input:checked"
  ).val();

  if (initial_behalf_of_partner_value === "No") {
    company_dropdown.hide();
    company_label.hide();
  }

  function fetch_company_users(legacy_company_id, callback = "") {
    jQuery.ajax({
      url: APPLICATION_URL,
      async: true,
      data: {
        action: "ab_get_company_users_using_legacy_id",
        legacy_company_id: legacy_company_id,
      },
      type: "GET",
      success: function (user_data) {
        populate_partner_contact_dropdown(user_data);
        if (callback != "") {
          callback();
        }
      },

      error: function (request, status, error) {
        console.log("Error: " + error);
        overview_container.removeClass("is_loading");
      },
    });
  }

  const ab_sp_dropdown = $(".ab_sp_dropdown select");

  ab_sp_dropdown.change(function () {
    var selected_value = $(this).val();
    // Use selected_value as legacy_company_id
    fetch_company_users(selected_value);
  });

  // Callback function to be executed after fetch_company_users completes
  function handle_second_partner_contact_options_rerender() {
    ab_partner_contact_dropdown.val(ab_second_partner_user_selected_val);
  }

  function storeOwnerDropdownValue() {
    var owner_id = $(this).val();
    sessionStorage.setItem("owner_mtd", owner_id);
  }

  if (
    initial_behalf_of_partner_value == "Yes" &&
    $(".gform_validation_errors").length
  ) {
    owner_dropdown.on("change", storeOwnerDropdownValue);
  }

  // Initial fetch
  if (window.location.href.indexOf("deal-registration") > -1) {
    if ($(".gform_validation_errors").length > 0) {
      let cid = ab_sp_dropdown.val();
      fetch_company_users(cid, handle_second_partner_contact_options_rerender);
    } else {
      const initial_legacy_company_id = ab_sp_dropdown.val();
      fetch_company_users(initial_legacy_company_id);
    }

    var selected_value = $(".ab_partner_company_dropdown").find("select").val();

    if (
      selected_value !== " " &&
      $(".gform_validation_errors").length &&
      $(".behalf_of_partner_dropdown").length
    ) {
      overview_container.addClass("is_loading");
      get_and_populate_user_data(selected_value);
    }
  }

  /*
   * Overrides user profile email field to read-only
   * path: /allbound-settings/?app=users&view=users_profile
   */
  $("#um_field_3_userprofile").prop("readonly", true);
});

function addValidation(form) {
  jQuery(form).validate({
    //rules: {
    //client_name: "required",
    //client_company: "required",
    //}
  });
}
